@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600&display=swap');
:root{
  --white: #fff;
  --red: rgb(237 5 7);
  --dark:#343a40;
  --orange: #F56800;
  --darkgray: #2c3e50;
  --lightgray:#95a5a6;
}

*{
  font-family: 'Red Hat Display', sans-serif;

}
/* Navbar Css start here */
.bg-white{
  background-color: var(--white) !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bg-dark .signin-link{
  color: var(--orange) !important;
}

.nav-link{
  font-weight: 500;
  color: var(--dark);
}

.nav-link.active{
  border-bottom: 3px solid var(--orange) !important;
}

/* Landing page Css  */
section.top-banner{
display: flex;
justify-content: center;
align-items: center;
background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.5)),
url('./assets/images/bg-home.jpg');
background-repeat: no-repeat;
background-position: center center;
background-size: cover;
background-attachment: fixed;
padding-top: 190px;
padding-bottom: 190px;
}

.home-heading{
  color: var(--white);
  font-family: 'Red Hat Display', sans-serif;
  font-size: 3.8rem;

}

.sub-heading{
  font-size: 30px;
  color: var(--orange);
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  
}

.p-orange{
  color: var(--orange);
  font-size: 25px;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
}

.p-white{
  color: var(--white);
  font-size: 18px;
  font-family: 'Red Hat Display', sans-serif;

}

/* Login css */
.login-bg{
  background-image: linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0.7)),
  url('../src/assets/images/industrial-port-de-barcelona-evening.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-img{
  padding-top: 25px;
  width: 450px;
}

.login-form{
  padding-top: 35px;
}

.login-heading{
  color: var(--white);
  font-family: 'Red Hat Display', sans-serif;
  font-size: 2.2rem;
  text-align: center;

}

.login-icons{
  font-size: 22px;
  color: var(--orange);
}

.footer-icons{
  font-size: 22px;


}

#basic-addon1{
  background-color: var(--darkgray);
}

.login-btn{
  width: 100%;
  background-color: var(--orange);
  border-radius: none !important;
  border: 1px solid var(--orange);
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}
.login-btn:hover{
  background-color: var(--darkgray);
  border: 1px solid var(--darkgray);
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;

}

.form-label{
  color: var(--lightgray);
  font-size: 15px;
  font-family: 'Red Hat Display', sans-serif;

}

.form-check-label{
  color: var(--lightgray);
  font-size: 15px;
  font-family: 'Red Hat Display', sans-serif;


}

.form-control:focus{
  box-shadow: none !important;
  border-color: var(--orange);
}

.fgps-text{
  color: var(--orange);
  font-weight: 500;
}
.fgps-text:hover{
  color: var(--darkgray);
  font-weight: 500;

}
a{
  text-decoration: none;
}

/* Footer Css */
.bg-footer{
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #ecf0f1;
}
section.ai-footer{
  background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.6)),
  url('./assets/images/black-structured.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 25px;
  padding-bottom: 25px;
}

.p-info{
  font-family: 'Red Hat Display', sans-serif;

}
.p-info-white{
  color: var(--white);
  font-size: 14px;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom: 0;

}

.p-info-white1{
  color: var(--white);
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom: 0;

}

.footer-bottom{
  background-color: var(--darkgray);
}

#footer h5{
	padding-left: 10px;
    border-left: 3px solid var(--orange);
    padding-bottom: 6px;
    margin-bottom: 20px;
    color:#ffffff;
}
#footer a {
    color: #ffffff;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
	padding: 3px 0;
}
#footer ul.social li a i {
    margin-right: 5px;
	font-size:25px;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}
#footer ul.social li:hover a i {
	font-size:30px;
	margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
	color:#ffffff;
}
#footer ul.social li a:hover{
	color:#eeeeee;
}
#footer ul.quick-links li{
	padding: 3px 0;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}
#footer ul.quick-links li:hover{
	padding: 3px 0;
	margin-left:5px;
	font-weight:700;
}
#footer ul.quick-links li a i{
	margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
    font-weight: 700;
}

@media (max-width:767px){
	#footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
}
}

.next-arrw{
  color: var(--white) !important;
}

/* about css */
.about-img{
  width: 500px;
}

.h4-heading{
  color: var(--darkgray);
  font-family: 'Red Hat Display', sans-serif;
  font-size: 18px;

}
.h4-heading-white{
  color: var(--white);
  font-family: 'Red Hat Display', sans-serif;

}
.seemore-btn{
  width: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--orange);
  border: 1px solid var(--orange);
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-transition: all 0.5s ease-in-out;

}
.seemore-btn:hover{
  background-color: var(--darkgray);
  border: 1px solid var(--darkgray);
}

.seedark-btn{
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--darkgray);
  border: 1px solid var(--darkgray);
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-transition: all 0.5s ease-in-out;

}

.export-left-side{
  background-color: var(--darkgray);
}

.export-cards{
  
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none !important;
  border: 2px solid var(--orange) !important;
  -webkit-transition: all 0.5s ease-in-out;
  /* color: var(--white); */
   /* min-height: 300px;
   height: 330px; */
}

.export-cards:hover{
  
  /* border-left: 5px solid var(--darkgray) !important; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.card-body-border{
  border: 2px solid var(--darkgray);
  margin: 3px;
}

.text-span{
  color: var(--orange);
}

section.partner-export{

background-image: url('./assets/images/partner-bg.jpg');
background-repeat: no-repeat;
background-position: center center;
background-attachment: fixed;
background-size: cover;
}

.left-side-partner{
  background-color: rgba(245, 104, 0, 0.8);
}

.sub-heading-white{
  color: var(--white);
}

.certi-cards{
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transition: all 0.5s ease-in-out;

}
.certi-cards:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.certi-img{
  width: 220px;
  height: 130px;
  object-fit: contain;
}


/* Abou us page CSS */

.about-us-banner{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0.7)),
  url('./assets/images/industrial-port-de-barcelona-evening.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  height: 200px;

}

.about-us-grid{
  border: 3px solid var(--white);
}

.border-orange{
  border: 3px solid var(--orange);
}

.abt-img{
  width: 500px;
}

.icon-dot-orange{
  color: var(--orange);
}

/* product page  css */

.product-cards{
border: none;
}
.product-image{
  height: 230px;
  object-fit: cover;
  transition: all 0.3s;
  transform: scale(1);
  aspect-ratio: 1 !important;
}

.product-image:hover{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transform: scale(1.02);
}

.prdctwish-icon{
  font-size: 22px;
}

.p-info-orange{
  color: var(--orange);
  font-weight: 600;
  margin-bottom: 0;
}

.btn.btn-primary.btn-sm{
  background-color: var(--orange) !important;
  border: var(--orange);
}
.btn.btn-primary.btn-sm:hover{
  background-color: var(--darkgray) !important;
  border: var(--darkgray) !important;
}

.outlined-btn{
  border: 2px solid var(--orange);
  background-color: #fff;
  padding: 8px 16px;
  color: var(--orange);
  transition: 0.3s;
}
.outlined-btn:hover{
  border: 2px solid var(--darkgray);
  background-color: #fff;
  padding: 8px 16px;
  color: var(--darkgray);
}
/* .accordion-button{
  background-color: RGB(255,170,102);
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
 
  background-color: RGB(255,170,102);
  box-shadow: none !important;
} */

.accordion-button:focus{
  /* border: none; */
  box-shadow: none;
}

.accordion-button:not(.collapsed){
  background-color: #ece6dd !important;
}

/* Product Details CSS */
.product-details-wrapper{
  position: relative;
  margin: auto;
  overflow: hidden;
}
.product-details-wrapper img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 400px;
  object-fit: contain;
  transform: scale(1);
}
.product-details-wrapper:hover img {
  transform: scale(1.1);
  cursor: zoom-in;
}
.preview-img-grid{
  border: 1px solid var(--lightgray);
  
}

.product-details-heading{
  font-size: 35px;
  font-family: 'Red Hat Display', sans-serif;
  color: var(--darkgray);

}

.price-text{
  font-size: 30px;
  font-weight: 600;
  font-family: 'Red Hat Display', sans-serif;

}

.p-instock{
font-size: 13px;
color: #27ae60;
margin-bottom: 0;
font-weight: 700 !important;
/* font-family: 'Red Hat Display', sans-serif; */

}
.p-outstock{
  font-size: 13px;
  color: red;
  margin-bottom: 0;
  font-weight: 700 !important;
  /* font-family: 'Red Hat Display', sans-serif; */
  
  }

/* Product-List Css */

.prdct-tableHead th{
  background-color: var(--darkgray) !important;
  color: var(--white);
}

.Enquiry-form-card{
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* .filters{
  width: 350px !important;
} */
.filter-grid{
  border: 1px solid #dfe6e9;
  border-radius: 2px;
  background-color: #ece6dd;
}

.btn-clear{
background-color: transparent;
border: none;
font-size: smaller;
-webkit-transition: all 0.5s ease-in-out;

}
.btn-clear:hover{
  background-color: #dddfdf;
}

/* Contact Us section Css */
.contact-leftcol{
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.5)),url(./assets/images/contactus.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.contact-info{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  color: var(--white);
}

/* Product category  */

.form-heading{
  font-size: 25px;
  color: var(--darkgray);
}

.admin-form-cards{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px gray dashed;
}
.btn.btn-primary{
  background-color: var(--orange);
  border: var(--orange);
}

.btn.btn-primary:hover{
  background-color: var(--darkgray);
  border: var(--darkgray);
}

.table>thead th {
  background-color: var(--darkgray);
  color: var(--white);
}


/* Sidebar Css */

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.sd-icons {
  font-size: 18px !important;
}

.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
  font-size: 14px !important;
}

.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
  min-height: auto !important;
}


/* Admin Dashboard css */

.dash-heading-h1{
  color: #747d8c;
  font-size: 1.8rem;
  font-weight: 400;
}

.dash-tile-1{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px;
  border-bottom: 4px solid #ff6b81;
}

.dash-tile-2{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px;
  border-bottom: 4px solid #70a1ff;
}

.dash-tile-3{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px;
  border-bottom: 4px solid #2ed573;
}
.dash-tile-4{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px;
  border-bottom: 4px solid #5352ed;
}
.dash-p{
  color: #a4b0be;
}
.dash-num{
  font-size: 1.8rem;
  color: #747d8c;
  font-weight: 500;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  background-color: var(--darkgray) !important;
  color: #fff !important;
}
/* .mui-tableHead{
  background-color: var(--darkgray) !important;
  color: #fff !important;

} */


/* wishlisr css */

.prod-name{
  font-size: 20px;
  font-weight: 500;
  color: var(--orange);
}
.add-cartbtn{
  padding: 10px 15px;
  background-color: var(--orange);
  border: 2px solid var(--orange);
  color: white;
  width: 100%;
}
.remove-cartbtn{
  padding: 10px 15px;
  background-color: var(--darkgray);
  border: 2px solid var(--darkgray);
  color: white;
  width: 100%;

}
.wishlist-image{
  width: 100%;
  object-fit: contain;
  height: 180px;
}
/*  */
/* .wishlist-wrap{
  border-bottom: 1px solid gray;
} */
/* pagination css */
.pagination {
  list-style: none;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
}

.active {
  background-color: var(--orange);
  border-radius: 50%;
}

.page-item {
  list-style: none;
  padding: 2px 12px;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.image-container {
 
  height: 100px ;
  width: 100px;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.image-container img{
 
  height: 100px ;
  width: 100px;
 
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}
.delete-icon {
  color: white; /* Change the color to improve visibility */
  font-size: 24px;
  cursor: pointer;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Add a subtle text shadow */
}



.modal-backdrop{
  background-color: rgba(0,0,0,0.2);
}

/* 
.product-grid-details p {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical !important;
  min-height: 45px !important;
} */



/* Media Query  */
 /* smartphones, iPhone, portrait 480x320 phones */ 
@media (min-width:320px)  { 
  
 .certi-img{
  width: 100%;
 }



}

@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }

@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }

@media (min-width:1281px) { /* hi-res laptops and desktops */ }